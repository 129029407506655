<template>
  <div>
    <ProductManagementInStore />
  </div>
</template>

<script>
import ProductManagementInStore from '../components/productsInStock/ProductManagementInStore.vue'

export default {
  components: {
    ProductManagementInStore,
  },
}
</script>
