<template>
  <div>
    <b-modal
      id="modal-change-status"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('ChangeStatus') + articleNumberTitle"
      hide-footer
      @close="onCancel"
    >
      <b-form style="display: flex; flex-direction:column">

        <p v-if="qFailed == false && showTeam == false && showLocation == false && showControlCheck == false">
          {{ $t('ChangeStatusOfItem') }}
        </p>


        <p v-if="qFailed == true">
          {{ $t('ProvideExplonation') }}
        </p>

        <b-form-group
          v-if="qFailed == true"
          id="input-group-1"
          label="Description:"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="$v.form.description.$model"
            :state="validateState('description')"
            aria-describedby="input-1-live-feedback"
          />
          <b-form-invalid-feedback
            id="input-1-live-feedback"
          >{{ $t('Required') }}</b-form-invalid-feedback>
        </b-form-group>
        <p v-if="showTeam == true && qFailed == false">
          {{ $t('SelectPreferredTeam') }}
        </p>
        <vue-select
          v-if="showTeam == true && qFailed == false"
          v-model="team"
          :options="getDropdownTeamName"
          :placeholder="$t('SelectTeam')"
          label="teamName"
          aria-describedby="input-1-live-feedback"
        />
        <!-- <p
          v-if=" showLocation == true && showTeam == false"
        >Please select your preferred location (optional)</p> -->
        <p
          v-if="showLocation == true && showTeam == false"
        >{{ $t('SelectPreferredLocation') }}</p>
        <vue-select
          v-if=" showLocation == true && showTeam == false"
          v-model="location"
          :options="getLocationsByType"
          placeholder="Select Location"
          label="locationName"
          aria-describedby="input-1-live-feedback"
        />
        <p v-if="showControlCheck == true && qFailed == false">
          {{ $t('SelectPreferredTeam') }}
        </p>
        <vue-select
          v-show="lStatus.newState != 'InPatternQualityNotPassed'"
          v-if="showControlCheck == true && qFailed == false"
          v-model="team"
          :options="getDropdownTeamName"
          :placeholder="$('SelectTeam')"
          label="teamName"
          aria-describedby="input-1-live-feedback"
        />
        <b-form-checkbox
          v-show="(id == team.teamNameId ) "
          v-if="(team !=null)"
          id="checkbox-1"
          v-model="isChecked"
          name="checkbox-1"
          :value="true"
          :unchecked-value="false"
          @input="ShowThem(isChecked)"
        >
          {{ $t('Click if you want to select an employee') }}
        </b-form-checkbox>
        <vue-select
          v-show="isChecked"
          v-if="cStatus.newState == 'InProduction' || cStatus.newState == 'InSewing' || (team !=null)"
          v-model="teamh"
          :options="getTeamOverview.users"
          :placeholder="$t('SelectEmployee')"
          label="name"
          aria-describedby="input-1-live-feedback"
        />
        <b-form-group
          v-if=" showLocation == true && showTeam == false && client != 'DrenushaXharra' "
          id="input-group-1"
          :label="`${$t('Notes')}:`"
          label-for="input-1"
        >
          <b-form-textarea
            id="input-1"
            v-model="notes"
            aria-describedby="input-1-live-feedback"
          />
          <p
            v-if="yesButtonClicked"
            class="text-danger"
          >
            {{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}
          </p>
          <b-form-invalid-feedback id="input-1-live-feedback">{{
            $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
        </b-form-group>
        <!-- <b-form-group
          v-if="nState === 'InHandSewing'"
          label="Handsewing Type:"
        >
          <b-form-select v-model="form.handsewingType">
            <b-form-select-option value="basic">
              Basic
            </b-form-select-option>
            <b-form-select-option value="advanced">
              Advanced
            </b-form-select-option>
          </b-form-select>
        </b-form-group> -->

        <div class="buttonsEverywhere">
          <b-button
            type="button"
            variant="none"
            class="buttonSubmit"
            @click="onSubmit"
          >
            {{ $t('Yes') }}
          </b-button>
          <b-button
            type="button"
            variant="danger"
            class="buttonCancel"
            style="margin-right: 15px;"
            @click="onCancel"
          >
            {{ $t("Cancel") }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>

import { validationMixin } from 'vuelidate';
import {
  required,
} from 'vuelidate/lib/validators';
import { mapGetters, mapActions } from 'vuex'
import { client } from '../../../domainConfig'

export default {
  mixins: [validationMixin],
  // eslint-disable-next-line vue/require-prop-types
  props: ['cStatus', 'articleNumberTitle', 'qFailed', 'orderFailed', 'showTeam', 'tStatus', 'showLocation', 'lStatus', 'controlCheckStatus', 'showControlCheck', 'id'],
  data() {
    return {
      yesButtonClicked: false,
      isChecked: false,
      form: {
        description: '',
      },
      team: null,
      teamh: null,
      selectedTeamPerson: null,
      location: null,
      client: client.clientName,
      notes: null,
    }
  },
  validations: {
    form: {
      description: {
        required,
      // minLength: minLength(3),
      // alpha,
      },
    },
  },
  computed: {
    ...mapGetters(['getTeamsNames', 'getDropdownTeamName', 'getLocationsByType', 'getTeamOverview']),
  },
  watch: {
    team(value) {
      this.teamh = null
      if (value != null) {
        this.teamByTeamNameId(value.teamNameId)
      }
      this.isChecked = false
    },
    isChecked(value) {
      if (value == false) {
        this.team.processingUserId = null
        this.teamh = null
      }
    },
  },
  methods: {
    ...mapActions([
      'teamByTeamNameId',
    ]),
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      if (this.qFailed == true) {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          return;
        }
        // const object = {
        //   newState: this.nState,
        //   message: this.form.description,
        // }
        this.orderFailed.description = this.form.description
        this.$emit('qualityChFailed', this.orderFailed)
        this.$refs.modal.hide()
        this.form.description = ''
        // object.newState = ''
        // object.message = ''
        setTimeout(() => { this.$v.$reset() }, 0)
        this.$nextTick(() => { this.$v.$reset() })
      } else if (this.showTeam == true && this.showLocation == false) {
        // const object = {
        //   newState: this.nState,
        //   newTeamNameId: this.team.teamNameId,
        // }
        this.isChecked = false;
        if (this.tStatus.newState != 'InPatternCompleted') {
          this.tStatus.newTeamNameId = this.team.teamNameId
        }
        if (this.teamh != null) {
          this.tStatus.processingUserId = this.teamh.userId
        }

        this.$emit('teamName', this.tStatus)
        this.$refs.modal.hide()
        this.team = null
        this.teamh = null
        this.cStatus.processingUserId = null;
        this.tStatus.processingUserId = null
        this.lStatus.notes = null
        // object.newState = ''
        // object.newTeamNameId = ''
        setTimeout(() => { this.$v.$reset() }, 0)
        this.$nextTick(() => { this.$v.$reset() })
      } else if (this.showLocation == true) {
        // const object = {
        //   newState: this.nState,
        //   newTeamNameId: this.team.teamNameId,
        // }
        this.lStatus.locationId = this.location ? this.location.locationId : null;
        if (!this.notes || this.notes.trim().length < 3) {
          this.yesButtonClicked = true

          // If notes is empty or less than 3 characters, show an error message
          this.$v.form.$touch();
          return;
        }
        this.lStatus.notes = this.notes ? this.notes : null;

        this.$emit('teamName', this.lStatus)
        this.$refs.modal.hide()
        this.team = null
        this.teamh = null
        this.cStatus.processingUserId = null;
        this.tStatus.processingUserId = null
        this.lStatus.notes = null
        // object.newState = ''
        // object.newTeamNameId = ''
        setTimeout(() => { this.$v.$reset() }, 0)
        this.$nextTick(() => { this.$v.$reset() })
      } else if (this.tStatus.newState == 'InPatternCompleted' || this.cStatus.newState == 'InSewing') {
        if (this.teamh != null) {
          this.cStatus.processingUserId = this.teamh.userId
          this.isChecked = false;
        }
        this.$emit('changeOrderStatus', this.cStatus)
        this.$refs.modal.hide()
        this.team = null
        this.teamh = null
        this.cStatus.processingUserId = null;
        this.tStatus.processingUserId = null
        this.lStatus.notes = null
        setTimeout(() => { this.$v.$reset() }, 0)
        this.$nextTick(() => { this.$v.$reset() })
      } else if (this.tStatus.newState == 'InPatternCompleted' || this.cStatus.newState == 'InSewing') {
        if (this.teamh != null) {
          this.cStatus.processingUserId = this.teamh.userId
          this.isChecked = false;
        }
        this.$emit('changeOrderStatus', this.cStatus)
        this.$refs.modal.hide()
        this.team = null
        this.teamh = null
        this.cStatus.processingUserId = null;
        this.tStatus.processingUserId = null
        this.lStatus.notes = null
        setTimeout(() => { this.$v.$reset() }, 0)
        this.$nextTick(() => { this.$v.$reset() })
      } else if (this.showControlCheck == true) {
        // const object = {
        //   newState: this.nState,
        //   newTeamNameId: this.team.teamNameId,
        // }
        this.controlCheckStatus.newTeamNameId = this.team.teamNameId

        this.$emit('teamName', this.controlCheckStatus)
        this.$refs.modal.hide()
        this.team = null
        this.teamh = null
        this.cStatus.processingUserId = null;
        this.tStatus.processingUserId = null
        this.lStatus.notes = null
        // object.newState = ''
        // object.newTeamNameId = ''
        setTimeout(() => { this.$v.$reset() }, 0)
        this.$nextTick(() => { this.$v.$reset() })
      } else {
        this.$emit('changeOrderStatus', this.cStatus)
        this.$refs.modal.hide()
        this.team = null
        this.teamh = null
        this.cStatus.processingUserId = null;
        this.tStatus.processingUserId = null
        this.lStatus.notes = null
      }
    },
    onCancel() {
      this.$refs.modal.hide()
      this.form.description = ''
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
    },
  },
}
</script>

<style lang="scss" scoped>
  form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  .flex-form {
    display: flex;
    flex-direction: column;
  }
  .buttonat{
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
  }
</style>
