<template>
  <b-sidebar
    id="productinstok-tracking-modal"
    header-class="headerModalToggle"
    :title="$t('ArticleNameTracking')"
    right
    shadow
    no-close-on-esc
    width="70%"
  >
    <template #title>
      <strong style="color: #101828;">{{ `${$t('ArticleNameTracking')}` }}</strong>
    </template>
    <template
      v-slot:header-close
    >
      <button
        style="color: #98A2B3;padding-left: 0px; padding-right: 0px;height: 60px; "
        class="fa fa-close"
        @click="onCancelToggle"
      />
    </template>
    <div
      style="margin-top: 0px; z-index: 2; padding: 20px;"
      @click="removeLists"
    >
      <div style="width: 100%;  padding-top: 16px; margin-top: 15px">
        <table
          ref="exportable_table"
          class="team_table"
          style="width: 100%; box-shadow: none; font-size: 12px"
        >
          <thead style="border-bottom: 1px solid #dcdfe6; color: #606266; text-align: left">
            <tr>
              <!-- <th style="padding: 10px; border-top-left-radius: 10px; color:#262E6C !important; font-size: 15px;">
                {{ $t('OrderNumber') }}
              </th> -->
              <th style="padding: 10px; color:#262E6C !important; font-size: 15px;">
                {{ $t('ArticleName') }}
              </th>
              <th style="padding: 10px; color:#262E6C !important; font-size: 15px;">
                {{ $t('State') }}
              </th>
              <th style="padding: 10px; color:#262E6C !important; font-size: 15px;">
                {{ $t('Time') }}
              </th>
              <th style="padding: 10px; color:#262E6C !important; font-size: 15px;">
                {{ $t('ExecutorName') }}
              </th>
              <th style="padding: 10px; color:#262E6C !important; font-size: 15px;">
                {{ $t('ProcessedBy') }}
              </th>
              <!-- <th style="padding: 10px; color:#262E6C !important; font-size: 15px;">
                {{ $t('Message') }}
              </th> -->
            </tr>
          </thead>
          <tbody v-if="getProductInStockTrackAndTrace.length > 0">
            <tr
              v-for="order in getProductInStockTrackAndTrace"
              :key="order.id"
            >
              <!-- <td style="padding: 9px; font-weight: bold; color: black;">
                {{ order.orderNumber }}
              </td> -->
              <td>
                {{ order.articleNumber }}
              </td>
              <td>
                {{ $t(order.state) }}
              </td>
              <td>
                {{ convertUTCDateToLoacalDate(order.created) }}

              </td>
              <td>
                {{ order.executorName }}
              </td>
              <td>
                {{ order.processingUser }}
              </td>
              <!-- <td style="padding: 9px; font-weight: bold; color: black;">
                {{ order.message }}
              </td> -->
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td
                colspan="5"
                style="padding: 9px; text-align: left;"
              >
                {{ $t('NoOrderWasSelected') }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <b-pagination
          v-model="page"
          :total-rows="getTotalPagesForMVM"
          :per-page="pageSize"
          first-number
          last-number
          align="fill"
          prev-class="prev-item"
          next-class="next-item"
          class="mt-1 mb-0"
        >
          <template #prev-text>
            <span class="previousOrNext"><b-icon-arrow-left />{{ $t('Previous') }}</span>
          </template>
          <template #next-text>
            <span class="previousOrNext">{{ $t('Next') }}<b-icon-arrow-right /></span>
          </template>
        </b-pagination>
      </div>
    </div>
  </b-sidebar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import * as XLSX from 'xlsx';
import moment from 'moment';

export default {
  data() {
    return {
      toggleSarch: false,
      noSearch: false,
      searchON: '',
      page: 1,
      pageSize: 12,
      wasOrNot: false,
      arrowCounter: 0,
      enterPressed: false,
    };
  },
  computed: {
    ...mapGetters(['productInStock_trackAndTrace', 'getTotalPagesForMVM', 'getProductInStockTrackAndTrace', 'getFilteredOrdersbyON', 'getCurrentPageForOrderTrackItems', 'getTotalItemsForOrderTrackItems']),
  },
  watch: {
    page(value) {
      this.productInStock_trackAndTrace({
        productInStockId: value, pageNumber: value, pageSize: this.pageSize,
      })
    },
    searchON(value) {
      if (value == '') {
        this.removeLists()
        // this.loadOrderTrackByOnAndAn({
        //   orderNumber: value,
        //   orderItemId: null,
        //   pageNumber: 1,
        //   pageSize: this.pageSize,
        //   successCallback: () => {
        //   },
        // })
      } else if (this.enterPressed) {
        // eslint-disable-next-line no-useless-return
        return;
      } else {
        this.searchByOrderNumber(value);
        this.noSearch = true
      }
    },
  },
  mounted() {
    // this.checkIfArticleNumWasNull()
    // console.log(this.$route.query)
  },
  methods: {
    ...mapActions(['getOrdersOverview',
      'searchByOrderNumber',
      'fshij',
      'fshijCN',
      'changeLoadingtoTrue',
      'loadOrderTrackByOnAndAn']),
    goBack() {
      const teamIdFromUrl = this.$route.query.teamId;
      console.log(teamIdFromUrl)
      // this.$router.push('/product-management-in-stock?page=1&pageSize=10'); // Without the query parameter
      this.$router.push({
        path: '/product-management-in-stock',
        query: {
          teamIdFromUrl,
          page: 1,
          pageSize: 15,
        },
      });
    },
    onCancelToggle() {
      this.$emit('onCancelToggle');
    },
    ExportExcel(type, fn, dl) {
      const elt = this.$refs.exportable_table;
      const wb = XLSX.utils.table_to_book(elt, { sheet: 'Sheet JS' });
      return dl
        ? XLSX.write(wb, { bookType: type, bookSST: true, type: 'base64' })
        : XLSX.writeFile(wb, fn || `SheetJSTableExport.${type || 'xlsx'}`);
    },
    convertUTCDateToLoacalDate(date) {
      const local = moment.utc(date).local().format('DD-MMM-YYYY h:mm A')
      return local
    },
    async searchByOn(value) {
      this.searchON = value
      this.noSearch = false
      await this.loadOrderTrackByOnAndAn({
        orderNumber: value,
        orderItemId: null,
        pageNumber: 1,
        pageSize: this.pageSize,
        successCallback: () => {
          // this.$router.push('/order-item-tracking')
        },
      })
      // this.checkIfArticleNumWasNull()
    },
    removeLists() {
      this.noSearch = false;
      this.fshij();
    },
    checkIfArticleNumWasNull() {
      // eslint-disable-next-line no-unused-vars
      for (let i = 0; i < this.getProductInStockTrackAndTrace.length; i++) {
        if (this.getProductInStockTrackAndTrace[0].orderItemId != this.getProductInStockTrackAndTrace[i].orderItemId) {
          // eslint-disable-next-line no-const-assign
          this.wasOrNot = true
        }
      }
      return this.wasOrNot
    },

    onArrowDown(ev) {
      ev.preventDefault()
      if (this.arrowCounter < this.getFilteredOrdersbyON.length - 1) {
        this.arrowCounter += 1;
        this.fixScrolling();
      }
    },

    onArrowUp(ev) {
      ev.preventDefault()
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
        this.fixScrolling()
      }
    },


    inputChanged(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') { return; }
      // this.getFilteredOrdersbyON = [];

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true
        this.searchByOn(this.getFilteredOrdersbyON[this.arrowCounter])
        this.removeLists()
        this.arrowCounter = 0
        setTimeout(() => {
          this.enterPressed = false
        }, 1000)
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    fixScrolling() {
      const liH = this.$refs.options[this.arrowCounter].clientHeight;
      console.log(liH)
      this.$refs.scrollContainer.scrollTop = liH * this.arrowCounter;
    },
  },
};
</script>

<style scoped lang="scss">
.backButton {
  /* Button styles */
  background: linear-gradient(52deg, rgb(38, 46, 108) 0%, rgb(155, 42, 92) 100%, rgb(255, 39, 79) 100%) 0% 0% no-repeat padding-box padding-box transparent;
    color: rgb(244, 241, 237);
  padding: 10px 80px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  // width: 200px;
  // margin-left: 75px;
  text-decoration: none;
  // margin-top: 70px;
}

.team_table td {
  padding: 16px 10px;
}

.backButton:hover {
  /* Hover styles */
  background-color: #0056b3;
}

.backButton:active {
  /* Active (clicked) styles */
  background-color: #003080;
}
.dropdrop::-webkit-scrollbar {
  display: none;

}

.dropdrop {
  display: inline-block;
  overflow: auto;
  position: absolute;
  background: white;
  width: 227px;
  box-shadow: none;
}

input[name="search-box"] {
  display: block;
  max-width: 500px;
  width: 95%;
  padding: 0.5rem 1rem;
  padding-left: 25px;
}

.black {
  position: relative;
}

.black input {
  border: none;
  box-shadow: none;
}

.search-item {
  padding: 5px;
  max-width: 500px;
  width: 100%;
  font-weight: 400;
  color: #82868c;
  font-size: 1rem;
  border-bottom: 1px solid #e8e8e8;
}

.search-item:last-child {
  border-bottom: none;
}

.search-item:hover {
  background: #82868c;
  color: white;
}

.search-item hr {
  color: lightgray;
  border-top: none;
  margin: -1.3rem 0 0.5rem 0;
}

.is-active {
  background-color: #dedede;
}
</style>
